import React from "react";
import { Table } from "antd";
import type { TableProps } from "antd";
import img from "../../assets/image/ShopCard.png";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

const CartTable = ({ allCartData }) => {
  const data = allCartData?.map((item) => {
    return {
      key: item.id,
      title: item.name,
      price: item.price,
      ProductImage: item.photo,
      quantity: item.quantity,
      subTotal: item.price * (item?.quantity || 1),
    };
  });
  const columns: any = [
    {
      title: "Product",
      dataIndex: "ProductImage",
      render: (img) => <img className="w-24" src={img} />,
    },
    {
      title: "Name",
      dataIndex: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "SubTotal",
      dataIndex: "subTotal",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      // title={() => 'Header'}
      // footer={Footer}
      pagination={false}
      className="miillg:mt-[20px]"
    />
  );
};

export default CartTable;
