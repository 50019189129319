import { HeartOutlined } from "@ant-design/icons";
import CartTable from "../CartPage/CartTable";
import WishlistTable from "./WishlistTable";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLayoutEffect } from "react";

const Wishlist = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((st: any) => st.auth);
  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const {
    data: favItems,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery(undefined);

  const allFavs = favItems?.data ?? [];

  console.log("favItems", favItems);

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  return (
    <div>
      <div className="flex items-center flex-nowrap justify-center pt-11 pb-11 gap-[4px] bg-[#fafafa] text-[#0b3954]">
        <HeartOutlined className="text-[15px] block" />
        <h2 className="m-0 font-bold uppercase">Wishlist</h2>
      </div>
      <div
        className={`milgg:pr-[20%] pr-[20px] maxlm:pr-[25px] maxlm:pl-[25px] pl-[20px] 
							milgg:pl-[20%] pt-5 bg-[#ffffff] min-h-[50vh] hiilg:pb-[50px]`}
      >
        <WishlistTable favItems={allFavs} />
      </div>
    </div>
  );
};

export default Wishlist;
