// import React from 'react'

// const HomeCatogery = () => {
// 	return (
// 		<div>HomeCatogery</div>
// 	)
// }

// export default HomeCatogery
import { useParams } from "react-router";
import ShopLayoutTitle from "../../../components/shopLayoutTitle/ShopLayoutTitle";
import {
  useGetAllProductsByCategoryQuery,
  useGetCategoryDetailsQuery,
} from "../../../redux/Service/api";
import ShopCart from "../../Shop/ShopCart";
import MenuRight from "../../../components/common/menuRight/MenuRight";
import { Link } from "react-router-dom";
import SkeletonCard from "../../../components/SkeletonCard";

const HomeCatogery = () => {
  const { id } = useParams();
  const {
    data: allProductsData,
    isError: isAllProductsError,
    isLoading: isAllProductsLoading,
  } = useGetAllProductsByCategoryQuery(39);

  const allProducts = allProductsData?.data?.data ?? null;
  console.log("id", id);

  console.log("allCategories", allProducts);

  return (
    <>
      {/* <h1>HomeCatogery ahmed</h1> */}
      <div className="flex flex-col">
        {/* <ShopLayoutTitle
          title={"Home"}
          PathTo={"/"}
          Page={"Shop"}
          subPage={"Agri"}
        /> */}
        <div className=" grid grid-cols-5 hiilg:grid-cols-4 miillg:grid-cols-3 maxlm:grid-cols-2 smscre:grid-cols-1 flex-wrap justify-start 
				gap-x-[10px] gap-y-[25px] items-start w-full bg-[#fff] pt-[20px] pb-[20px] mb-8">
          {isAllProductsLoading && <SkeletonCard />}
          {allProducts?.map((product) => {
            return (
              <div
                className="relative overflow-x-hidden w-[100%] duration-300 group hover:shadow-[0px_0px_5px_0px_rgba(0,0,0,0.3)] pt-[5px] pb-[5px]"
                key={product.id}
              >
                <MenuRight product={product} />
                <Link
                  to={`/shop/catogery/products/${product.id}`}
                  className="pt-[12px] w-[100%]"
                >
                  <div className="h-[180px] smscre:h-[250px] w-full flex justify-center items-center">
                    <img src={product.photo} className=" h-full" alt="" />
                  </div>
                  <div className="flex flex-col items-center mt-4">
                    <p className="font-bold uppercase">{product.name}</p>
                    <p className="text-[#888] font-bold text-[13px]">
                      <span className="pr-1">{product.price}</span>
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HomeCatogery;
