import BillingDetails from "./BillingDetails/BillingDetails";
import CheckoutOrder from "./CheckoutOrder";
import Login from "./Login";
import Payment from "./Payment/Payment";
import "./checkout.scss";
const Checkout = () => {
  return (
    <div>
      {/* <Login /> */}
      <div className="flex gap-6 mt-5">
        <div className="w-[70%]">
          {/* <BillingDetails /> */}
          <Payment />
        </div>
        <div className="w-[30%]">
          <CheckoutOrder />
        </div>
      </div>
    </div>
  );
};
export default Checkout;
