import { MailFilled, PhoneFilled, PhoneOutlined } from "@ant-design/icons";

const ContactService = () => {
  return (
    <div className="p-8 bg-[#EDF4F4] flex flex-col gap-5 xllg:w-full">
      <h2
        className="text-[#0B3954] 
 maxlm:text-[16px] text-[23px] font-bold mb-5 maxlm:mb-0"
      >
        ContactService
      </h2>
      <p className="maxlm:text-[14px] leading-[27px]">
        Give us a call or send us an email whenever you like.
      </p>
      <div className="bg-[#fff] text-center pt-4 pb-4 text-[#0B3954]">
        <PhoneFilled className="pr-2 text-[20px] maxlm:text-[14px]" />
        <span className="maxlm:text-[14px]">00966500350480</span>
      </div>
      <div className="bg-[#fff] text-center pt-4 pb-4 text-[#0B3954]">
        <MailFilled className="pr-2 text-[20px] maxlm:text-[14px]" />
        <span className="maxlm:text-[14px]">sales@konozelectric.com</span>
      </div>
    </div>
  );
};

export default ContactService;
