import MenuRight from "../../../components/common/menuRight/MenuRight";
import { Link } from "react-router-dom";

const PopulatedCatogery = ({ allSpecialProducts }) => {
  return (
    <div className="flex flex-wrap mt-11 ">
      {allSpecialProducts?.map((product) => (
        <div className="relative hilg:w-[33%] xllg:w-[50%] w-[20%] maxlm:w-[50%] group overflow-x-hidden">
          <MenuRight product={product} />
          <Link
            to={""}
            className="flex flex-col items-start w-full border-[1px] solid border-[#e1e1e1] p-4 bg-[#ffff] groupe"
          >
            <img src={product.photo} className="w-full h-full" alt="" />
            <div className="">
              <h3 className="maxlm:text-[13px]">{product.name}</h3>
              <h2 className="font-bold text-[16px] maxlm:text-[11px]">
                {product.short_description}
              </h2>
              <p className="maxlm:text-[12px]">
                <span>{product.price}</span>
                <span> SAR</span>
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PopulatedCatogery;
