import React, { useEffect } from "react";
import { Button, Form, Input, Space } from "antd";
import { useSelector } from "react-redux";

const AcountForm: React.FC = () => {
  const [form] = Form.useForm();
  const { userData } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue(userData);
    }
  }, [userData, form]);

  const onFinish = (values: any) => {
    console.log("Form Submitted:", values);
  };

  return (
    <div className="w-[68%] miillg:w-[100%] acountForm">
      <Form
        form={form}
        name="accountForm"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item name="email" label="Email Address">
          <Input className="h-[50px]" placeholder="Enter e-mail" disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input className="h-[50px]" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
        >
          <Input className="h-[50px]" placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <Input className="h-[50px]" placeholder="Enter address" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AcountForm;
