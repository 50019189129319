import { RightCircleOutlined } from "@ant-design/icons";
const PopulatedText = () => {
  return (
    <div className="flex items-center justify-between miillg:flex-col miillg:justify-start miillg:gap-[30px]">
      <div>
        <h3
          className={`text-[#0B3954] text-[33px] font-bold maxlm:text-[25px]`}
        >
          Most sold this week
        </h3>
        <p className="maxlm:text-[14px]">
          Top 10 Most Sold This Week, Next Day Delivery.
        </p>
        <p className="mt-5 maxlm:text-[14px]">
          Access the products you are looking for 24/7 online, buy with a single
          click! You can access the products you are looking for with a single
          click.
        </p>
      </div>
      {/* <div className="flex items-center gap-2 flex-nowrap group Populated miillg:w-full">
        <RightCircleOutlined className="text-[#00B6AD] text-[14px] group-hover:text-[#0B3954] duration-[0.3s] PopulatedArrow"/>
        <p className="text-[#00B6AD] font-bold group-hover:text-[#0B3954] duration-[0.3s] maxlm:text-[14px]">
          Top 100 Most Sold
        </p>
      </div> */}
    </div>
  );
};

export default PopulatedText;
