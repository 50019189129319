import React, { useState } from "react";
import { Modal, Space, message, Button, Input } from "antd";
import { useCreateAdminMutation } from "../../redux/Service/api";
// import { useCreateAdminMutation } from "../../redux/Service/api";

const AddAdmin = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [addAdmin, { isLoading, isError, error }] = useCreateAdminMutation();

  const handleAddAdmin = async () => {
    if (!name) {
      message.error("Please add the Name");
      return;
    }
    if (!email) {
      message.error("Please add the Email");
      return;
    }
    if (!password) {
      message.error("Please add the Password");
      return;
    }
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      setPasswordError(
        "Password must be at least 8 characters and contain both lower and upper case letters"
      );
      return;
    }

    const requestBody = {
      name,
      email,
      password,
      role: "admin",
    };

    try {
      const res = await addAdmin(requestBody);

      setName("");
      setEmail("");
      setPassword("");
      setPasswordError("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Admin added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Admin :", error);
      message.error("Error adding Admin. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Create Admin"
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer={null}
      style={{ direction: "ltr" }}
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Input
            size="large"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            size="large"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            size="large"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          {passwordError && <p className="text-red-500">{passwordError}</p>}
        </div>

        <div className="flex justify-end">
          <Button type="primary" onClick={handleAddAdmin} loading={false}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddAdmin;
