import {
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AddBrand from "./AddBrand"; // Changed from AddCategory
import EditBrand from "./EditBrand"; // Changed from EditCategory
import {
  useDeleteBrandMutation,
  useGetAllBrandsQuery,
} from "../../redux/Service/api"; // Updated API hooks
import ServerError from "../../components/utils/ServerError";

const { confirm } = Modal;

function BrandsControl() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [brandIdForEdit, setBrandIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const {
    data: allBrandsData,
    isError: isAllBrandsError,
    isLoading: isAllBrandsLoading,
  } = useGetAllBrandsQuery(undefined); // Updated API query

  const allBrandsSameLevel = flattenBrands(allBrandsData?.data ?? []);

  function flattenBrands(brands) {
    return brands.reduce((acc, brand) => {
      acc.push({ ...brand });

      if (brand.brands && brand.brands.length > 0) {
        acc.push(...flattenBrands(brand.brands));
      }

      return acc;
    }, []);
  }

  const [deleteBrand, { isLoading: delIsLoading }] = useDeleteBrandMutation(); // Updated mutation

  const handleDeleteBrand = async (id) => {
    confirm({
      title: "Do you really want to delete this brand?",
      icon: <ExclamationCircleFilled />,
      content: "Upon clicking OK, this brand will be removed.",
      async onOk() {
        try {
          const res = await deleteBrand(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Brand deleted successfully.");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (photo) =>
        photo ? (
          <img
            src={photo}
            alt="Brand"
            style={{ width: 50, height: 50, objectFit: "contain" }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, brand) => (
        <div className="flex gap-2">
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                setOpenEditModal(true);
                setBrandIdForEdit(brand.id);
                setRerenderEditKey((prev) => prev + 1);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="text"
              icon={<DeleteFilled />}
              danger
              onClick={() => handleDeleteBrand(brand.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section className="brands section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">Brands</h2>
      {isAllBrandsError && <ServerError />}

      <div className="flex justify-center mb-5">
        <Button
          type="primary"
          onClick={() => setOpenAddModal(true)}
          icon={<PlusOutlined />}
        >
          Add New Brand
        </Button>
      </div>

      <div className="container mx-auto my-5">
        <Table
          columns={columns}
          dataSource={allBrandsSameLevel}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          loading={isAllBrandsLoading || delIsLoading}
        />
      </div>

      <EditBrand
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        brandIdForEdit={brandIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />
      <AddBrand
        allBrands={allBrandsSameLevel}
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
    </section>
  );
}

export default BrandsControl;
