import { useParams } from "react-router";
import img from "../../assets/image/ShopCard.png";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import CatogeryTab from "./CatogeryTab";
import DeatailsContant from "./DeatailsContant";
import "./ShopCatogeryDetails.scss";
import { useGetOneProductQuery } from "../../redux/Service/api";
const ShopCatogeryDetails = () => {
  const { id } = useParams();
  const {
    data: productDetails,
    isError: isProductDetailsError,
    isLoading: isProductDetailsLoading,
  } = useGetOneProductQuery(id);

  const product = productDetails?.data ?? null;

  return (
    <>
      <div className="flex flex-col w-full milgg:pr-[27%] milgg:pl-[27%] pl-[50px] pr-[50px] ShopDetails">
        <ShopLayoutTitle
          title={"Home"}
          PathTo={"/"}
          Page={"Shop"}
          subPage={"Agri"}
          pageDetails={"AgriDetails"}
        />
        <div className="flex gap-[30px] miillg:flex-col">
          <div className="min-w-[50%]">
            <div className="border-[1px] miillg:h-[600px] solid border-[#ccc] mb-4">
              <img
                src={product?.photo}
                alt=""
                className="w-full h-full max-h-[80vh] object-cover"
              />
            </div>
          </div>
          <DeatailsContant product={product} />
        </div>
        <h2
          className={`uppercase text-[#0b3954] text-center 
        font-bold mb-6 border-b-[1px] solid border-[#0b3954] pb-10 mt-11 text-[30px]`}
        >
          Description
        </h2>
        <h2 className="text-[#54595F] text-[15px] mb-10">
          {product?.description}
        </h2>
        {/* <h3 className="text-center text-[#222] text-[20px] font-bold">
          Related products
        </h3>
        <CatogeryTab /> */}
      </div>
    </>
  );
};
export default ShopCatogeryDetails;
