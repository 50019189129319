import { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
// import Header from "./header";
import Footer from "./Footer";
import Header from "./Header";
import Banner from "./Header/banner/Banner";
// import Header from "./header";
// import Banner from "./header/banner/Banner";
// import Banner from "./header/banner/Banner";

function RootLayout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div className="pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] bg-[#007dab22]">
        <Header />
      </div>
      <Banner />

      <Outlet />

      <div className="pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] bg-[#007dab39] miillg:pb-[110px]">
        <Footer />
      </div>
    </>
  );
}

export default RootLayout;
