import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

type IProps = {
  title: string;
  PathTo: string;
  Page: string;
  subPage?: string;
  pageDetails?: string
};
const ShopLayoutTitle = ({ title, PathTo, Page, subPage, pageDetails }: IProps) => {
  return (
    <div className="pt-2 pb-2 mb-11">
      <Link to={PathTo} className="mr-1 text-[#a3a3a3] text-[14px]">{title}</Link>
      <RightOutlined className="text-[12px] mr-1 text-[#a3a3a3]" />
      <span className="text-[14px]">{Page}</span>
      {subPage &&


        <>
          <RightOutlined className="text-[12px] mr-1 text-[#a3a3a3]" />
          <span className="text-[14px]">{subPage}</span>
        </>
      }
      {

        pageDetails
        &&
        <>
          <RightOutlined className="text-[12px] mr-1 text-[#a3a3a3]" />
          <span className="text-[14px]">{pageDetails}</span>
        </>
      }

    </div>
  );
};

export default ShopLayoutTitle;
