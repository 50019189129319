import { ShoppingCartOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

const CartEmpty = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full min-h-[40vh] p-6 gap-3">
      <ShoppingCartOutlined className="text-[40px] text-[#000000]" />
      <h1 className="text-[30px] font-bold text-[#000000]">
        YOUR SHOPPING CART IS EMPTY
      </h1>
      <p className="text-[#8c8c8c]">
        We invite you to get acquainted with an assortment of our shop. Surely
        you can find something for yourself!
      </p>
      <Link to={"/shop"} className="bg-[#007dab] text-[14px] pr-7 text-[#fff] font-bold pl-7 pt-2 pb-2 rounded-[5px] hover:bg-[#000000] duration-300">
        Return To Shop
      </Link>
    </div>
  );
};

export default CartEmpty;
