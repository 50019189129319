import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/image/ShopCard.png";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import ShopCart from "../Shop/ShopCart";
import {
  EyeOutlined,
  HeartOutlined,
  Loading3QuartersOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import DrawerDetails from "./DrawerDetails";
import { useAddToCartMutation } from "../../redux/Service/api";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import { useState } from "react";
const DeatailsContant = ({ product }) => {
  const [productCount, setProductCount] = useState(1);

  const { isAuthenticated } = useSelector((st: any) => st.auth);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const info = (msg) => {
    messageApi.open({
      type: msg ? "error" : "success", // Use "error" instead of "danger"
      content: msg || "Item is added", // Simplified conditional content

      style: {
        marginTop: "10vh",
      },
    });
  };

  const [
    addToCart,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();
  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    try {
      const res: any = await addToCart({
        item_id: product?.id,
        quantity: productCount,
      });
      info(res?.message);

      setProductCount(1);
    } catch (error: any) {
      console.error("Error adding item to cart:", error);
    }
  };

  return (
    <div className="flex flex-col gap-5 w-[100%]">
      {contextHolder}
      <div className="min-h-[50%]">
        <h2 className="text-[#222] text-[18px] font-bold">{product?.name}</h2>
        <p className="text-[#00968F]">{product?.price} SAR</p>
        <p>{product?.short_description}</p>
        {/* <p className="text-[#222]">{product?.description}</p> */}
      </div>
      <div>
        <Button
          loading={addIsLoading}
          className="w-full uppercase bg-[#009e97] text-[#fff] pt-2 pb-2 flex justify-center items-center flex-nowrap gap-3 cursor-pointer hover:!bg-[#009e97] hover:!text-[#fff] border-none"
          onClick={handleAddToCart}
        >
          <ShoppingOutlined />
          <span>Add to Cart</span>
        </Button>

        {/* <p className="mt-4 mb-4 text-center uppercase text-[#222]">OR</p> */}
        {/* <Link
          to={"/Cart/checkout"}
          className=" bg-[#2E7D32] text-[13px] uppercase text-[#fff] pt-2 pb-2 flex justify-center items-centerflex-nowrap gap-3 cursor-pointer"
        >
          <ShoppingOutlined />
          <span>Buy Now</span>
        </Link> */}
        {/* <p className="capitalize bg-[#009e97] w-fit m-auto mt-3 pr-4 pl-4 rounded-[5px] text-[#fff] pt-2 pb-2 flex justify-center items-centerflex-nowrap gap-3 cursor-pointer">
          Add to Quote
        </p> */}
        <div>
          <p className="cursor-pointer hover:text-red-500">
            <HeartOutlined className="text-[13px] mr-3" />
            <span className="text-[13px]">Add to Wishlist</span>
          </p>
          {/* <p className="cursor-pointer hover:text-red-500">
            <Loading3QuartersOutlined className="text-[13px] mr-3" />
            <span className="text-[13px]">Add to Compare</span>
          </p> */}
        </div>
        <div className="flex gap-5 mt-4 flex-nowrap miillg:flex-wrap">
          {/* <p>
            <span>SKU : </span>
            <span className="text-[#888888]">AURA37</span>
          </p> */}
          <p>
            <span>Category : </span>
            <span className="text-[#888888]">{product?.category?.name}</span>
          </p>
          <p>
            <span>Brand : </span>
            <span className="text-[#888888]">{product?.brand?.name}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default DeatailsContant;
