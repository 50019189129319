import React from "react";
import { Button, Form, Input, Select, Space } from "antd";

const { Option } = Select;





const AuthRegisterForm: React.FC = () => {
  const [form] = Form.useForm();

  const onGenderChange = (value: string) => {
    switch (value) {
      case "male":
        form.setFieldsValue({ note: "Hi, man!" });
        break;
      case "female":
        form.setFieldsValue({ note: "Hi, lady!" });
        break;
      case "other":
        form.setFieldsValue({ note: "Hi there!" });
        break;
      default:
    }
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({ note: "Hello world!", gender: "male" });
  };

  return (
    <div className="w-full Login p-4 border-[1px]">
			      <label className="text-[#0b3954] font-bold text-[23px] mb-6 block">Register</label>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
      >
        <Form.Item name="Email" label="Email address" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
				<Form.Item label="User Type">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>
			<Form.Item name="FristName" label="Frist Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
			<Form.Item name="LastName" label="Last Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
			<Form.Item name="PhoneNumber" label="Phone Number" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
				<label className="text-[12px] font-bold">
				Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
				</label>
        <Form.Item  className="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            className=" bg-[#009e97] pr-7 pl-7 pt-4 pb-4 hover:!bg-[#0b3954] font-bold w-full text-[13px]"
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthRegisterForm;
