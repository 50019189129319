import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/Service/api";
import { reduxLogin, saveUserData } from "../../redux/Slices/authSlice";
import { useDispatch } from "react-redux";

type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const UserLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");

  const [loginUser, { isLoading, isError, error }] = useLoginMutation();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("login values:", values);
    // return;
    try {
      const result: any = await loginUser(values);
      console.log("login result", result);

      const token = result?.data?.data?.token ?? null;
      if (token) {
        dispatch(reduxLogin());
        localStorage.setItem("knoozToken", token);
        dispatch(saveUserData(result?.data?.data?.user));
        navigate("/");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.message ||
            "something went wrong"
        );
        console.log("err");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };
  return (
    <div className="w-full Login ">
      {errorMessage && (
        <p className="text-red-500 text-center">{errorMessage}</p>
      )}
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="!w-full p-0 mt-[20px]"
        layout="vertical"
      >
        <Form.Item<FieldType>
          label="email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input className="h-[40px]" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="h-[40px]" />
        </Form.Item>

        {/* <Form.Item<FieldType>
          name="remember"
          valuePropName="checked"
          className=""
        >
          <Checkbox className="pr-[100px]">Remember me</Checkbox>
          <Link to={""} className="text-[#222]">
            Lost password?
          </Link>
        </Form.Item> */}

        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            className=" bg-[#009e97] pr-7 pl-7 pt-4 pb-4 hover:!bg-[#0b3954] font-bold w-full"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserLogin;
