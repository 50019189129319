import React from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log("Success:", values);
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const AuthLoginForm: React.FC = () => {
  return (
    <div className="w-full p-4 border-[1px] Login solid">
      <label className="text-[#0b3954] font-bold text-[23px]">Login</label>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="!w-full p-0 mt-[20px]"
      >
        <Form.Item<FieldType>
          label="Username or email"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input className="h-[40px]" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="h-[40px]" />
        </Form.Item>

        <Form.Item<FieldType> name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-[#009e97] pr-7 pl-7 pt-4 pb-4 hover:!bg-[#0b3954] font-bold w-full"
          >
            Login
          </Button>
        </Form.Item>
        <Link to={""} className="block w-full text-center underline">
          Lost your password?
        </Link>
      </Form>
    </div>
  );
};

export default AuthLoginForm;
