import { CarOutlined } from "@ant-design/icons";
import { Progress, ProgressProps } from "antd";
import React from "react";

const CartDelevery = () => {
  const twoColors: ProgressProps["strokeColor"] = {
    "0%": "#108ee9",
    "100%": "#87d068",
  };
  return (
    <div className="bg-[#fff]  p-6 w-full">
      <div className="flex flex-none gap-2">
        <CarOutlined className="text-[20px]" />
        <p className="flex flex-nowrap">
          <span>Spend</span>
          <p className="pl-2 pr-2">
            <span className="text-[17px] font-bold">798.09</span>
            <span className="text-[17px] font-bold">SAR</span>
          </p>
          <span>to get free shipping</span>
        </p>
      </div>
      <Progress percent={50.9} strokeColor={twoColors} />
    </div>
  );
};

export default CartDelevery;
