import React from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
  Form,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useAddProductMutation,
  useGetAllBrandsQuery,
  useGetAllCategoriesQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";

const AddProduct = ({ setOpenAddModal, openAddModal }) => {
  const [form] = Form.useForm();
  const { data: allCategoriesData } = useGetAllCategoriesQuery(undefined);
  const { data: allBrandsData } = useGetAllBrandsQuery(undefined);
  const [addProduct, { isLoading }] = useAddProductMutation();

  const allCategories =
    getCatWithNoSubs(allCategoriesData?.data)?.filter(
      (cat) => cat.parent_id !== null
    ) ?? [];

  function getCatWithNoSubs(categories) {
    const leafCategories = [];

    categories?.forEach((cat) => {
      if (cat.categories.length > 0) {
        // Recursively collect leaf categories from children
        leafCategories.push(...getCatWithNoSubs(cat.categories));
      } else {
        // If no children, add the current category
        leafCategories.push(cat);
      }
    });

    return leafCategories;
  }

  const allBrands = allBrandsData?.data ?? [];

  const handleAddProduct = async (values) => {
    const {
      name,
      name_en,
      price,
      priceAfterDiscount,
      short_description,
      short_description_en,
      description,
      descriptionEN,
      available,
      productionDate,
      categoryId,
      brandId,
    } = values;

    const iconImage = values.icon[0]?.originFileObj;
    const subImages = values.subImages.map((file) => file.originFileObj);

    if (!iconImage || !subImages.length) {
      message.error("Please upload required images.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("name_en", name_en);
    formData.append("available", available);
    formData.append("photo", iconImage);
    formData.append("description", description);
    formData.append("description_en", descriptionEN);
    formData.append("short_description", short_description);
    formData.append("short_description_en", short_description_en);
    formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    formData.append("brand_id", brandId);
    formData.append("price", price);

    subImages.forEach((file, index) => {
      formData.append(`photos[${index}]`, file);
    });
    formData.append(
      "discount",
      priceAfterDiscount && priceAfterDiscount > 0 ? priceAfterDiscount : 0
    );

    try {
      await addProduct(formData);
      form.resetFields();
      message.success("Product added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Product:", error);
      message.error("Error adding Product. Please try again.");
    }
  };

  return (
    <Modal
      title="Add Product"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={1000}
      footer={null}
      className="add-product-modal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddProduct}
        initialValues={{ available: "active" }}
      >
        <Space direction="vertical" className="w-full space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <Form.Item
                name="name"
                label="Product Name"
                rules={[
                  { required: true, message: "Please enter product name" },
                ]}
              >
                <Input placeholder="Product Name" />
              </Form.Item>

              <Form.Item
                name="name_en"
                label="Product Name (EN)"
                rules={[
                  {
                    required: true,
                    message: "Please enter product name in English",
                  },
                ]}
              >
                <Input placeholder="Product Name (EN)" />
              </Form.Item>

              <Form.Item name="short_description" label="Short Description">
                <TextArea rows={4} placeholder="Short Description" />
              </Form.Item>

              <Form.Item
                name="short_description_en"
                label="Short Description (EN)"
              >
                <TextArea rows={4} placeholder="Short Description (EN)" />
              </Form.Item>

              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: "Please enter product price" },
                ]}
              >
                <Input type="number" placeholder="Price" />
              </Form.Item>
            </div>

            <div className="space-y-4">
              <Form.Item name="priceAfterDiscount" label="Price After Discount">
                <Input type="number" placeholder="Price After Discount" />
              </Form.Item>

              <Form.Item
                name="categoryId"
                label="Select Category"
                rules={[
                  { required: true, message: "Please select a category" },
                ]}
              >
                <Select
                  placeholder="Select Category"
                  options={allCategories.map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                name="brandId"
                label="Select Brand"
                rules={[{ required: true, message: "Please select a brand" }]}
              >
                <Select
                  placeholder="Select Brand"
                  options={allBrands.map((brand) => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                name="productionDate"
                label="Production Date"
                rules={[
                  {
                    required: true,
                    message: "Please select a production date",
                  },
                ]}
              >
                <DatePicker className="w-full" />
              </Form.Item>
            </div>
          </div>

          {/* Description Fields */}
          <div className="space-y-4">
            <Form.Item name="description" label="Description">
              <TextArea rows={6} placeholder="Description" />
            </Form.Item>

            <Form.Item name="descriptionEN" label="Description (EN)">
              <TextArea rows={6} placeholder="Description (EN)" />
            </Form.Item>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Form.Item
              name="icon"
              label="Upload Main Image"
              valuePropName="fileList"
              getValueFromEvent={({ fileList }) => fileList}
              rules={[
                { required: true, message: "Please upload the main image" },
              ]}
            >
              <Upload
                customRequest={({ onSuccess }) => onSuccess("ok")}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>
                  Upload Main Image (Max: 1)
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name="subImages"
              label="Upload Sub Images"
              valuePropName="fileList"
              getValueFromEvent={({ fileList }) => fileList}
              rules={[{ required: true, message: "Please upload sub images" }]}
            >
              <Upload
                customRequest={({ onSuccess }) => onSuccess("ok")}
                listType="picture"
                maxCount={4}
                multiple
              >
                <Button icon={<UploadOutlined />}>
                  Upload Sub Images (Max: 4)
                </Button>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Add Product
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddProduct;
