import { RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const PaymentHeaderLinks = () => {
  const [Active, setActive] = useState("Shopping");
  return (
    <div className="flex justify-between gap-[18px]  miillg:w-full miillg:flex-wrap miillg:justify-center text-[#fff]">
      <Link
        to={"/Cart"}
        className={` flex items-center gap-2 flex-nowrap group`}
        onClick={() => setActive("Shopping")}
      >
        <span
          className={`${
            Active === "Shopping" ? "text-[#fdb03b]" : ""
          } group-hover:text-[#fdb03b] duration-300 text-[18px]`}
        >
          Shopping cart
        </span>
        <RightOutlined className="text-[12px]" />
      </Link>
      {/* <Link
        to={"/Cart/checkout"}
        className={`flex items-center gap-2 flex-nowrap group`}
        onClick={() => setActive("Billing")}
      >
        <span
          className={`${
            Active === "Billing" ? "text-[#fdb03b]" : ""
          } group-hover:text-[#fdb03b] duration-300 text-[18px]`}
        >
          Billing details
        </span>
        <RightOutlined className="text-[12px]" />
      </Link> */}
      <Link
        to={"/Cart/checkout"}
        className={`flex items-center gap-2 flex-nowrap group`}
        onClick={() => setActive("Payment")}
      >
        <span
          className={`${
            Active === "Payment" ? "text-[#fdb03b]" : ""
          } group-hover:text-[#fdb03b] duration-300 text-[18px]`}
        >
          Payment
        </span>
        {/* <RightOutlined className="text-[12px]" /> */}
      </Link>
      {/* <Link
        to={""}
        className={` flex items-center gap-2 flex-nowrap group`}
        onClick={() => setActive("Order")}
      >
        <span
          className={`${
            Active === "Order" ? "text-[#fdb03b]" : ""
          } group-hover:text-[#fdb03b] duration-300 text-[18px]`}
        >
          Order status
        </span>
        <RightOutlined className="text-[12px]" />
      </Link> */}
    </div>
  );
};

export default PaymentHeaderLinks;
