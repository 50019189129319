import React, { useState } from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  Row,
  Col,
  Select,
  Form,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useAddCategoryMutation } from "../../redux/Service/api";

const AddCategory = ({ setOpenAddModal, openAddModal, allCategories }) => {
  const [form] = Form.useForm();
  const [modalKey, setModalKey] = useState(0);

  const [file, setFile] = useState(null);

  const [addCategory, { isLoading }] = useAddCategoryMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddOffer = async (values) => {
    const { nameEN, nameAR, parentCategory } = values;

    if (!file) {
      message.error("Please upload a file");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    if (parentCategory) {
      formData.append("parent_id", parentCategory);
    }

    try {
      const res = await addCategory(formData);
      setFile(null);
      form.resetFields();
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Category added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Category:", error);
      message.error(
        "An error occurred while adding the category. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Category"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddOffer}
        initialValues={{
          nameAR: "",
          nameEN: "",
          parentCategory: null,
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="small">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="nameAR"
                label="Name in Arabic"
                rules={[
                  { required: true, message: "Please enter the Arabic name" },
                ]}
              >
                <Input placeholder="Name in Arabic" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="nameEN"
                label="Name in English"
                rules={[
                  { required: true, message: "Please enter the English name" },
                ]}
              >
                <Input placeholder="Name in English" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="parentCategory" label="Parent Category">
                <Select
                  placeholder="Select parent category"
                  options={allCategories?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Upload Image" required>
                <Upload
                  customRequest={({ file, onSuccess }) => {
                    try {
                      onSuccess(undefined);
                    } catch (error) {
                      console.error("Error handling file:", error);
                    }
                  }}
                  onChange={({ fileList }) => {
                    handleFileChange(fileList);
                  }}
                  listType="picture"
                  maxCount={1}
                >
                  <Button icon={<DownloadOutlined />}>
                    Upload Image (Max: 1)
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="w-full"
              >
                Add Category
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddCategory;
