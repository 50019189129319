import { Button, Dropdown, Input, message, Modal, Space, Table } from "antd";
import { useRef, useState } from "react";
import {
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import {
  useAdminGetAllUserQuery,
  useDeleteUserMutation,
} from "../../redux/Service/api";
import AddAdmin from "./AddAdmin";

const { confirm } = Modal;

const UsersControl = () => {
  const [searchText, setSearchText] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // const [
  //   deleteUser,
  //   { isLoading: delIsLoading, isError: delIsError, error: delError },
  // ] = useDeleteUserMutation();

  const {
    data: allUsersData,
    isError: isUsersDataError,
    isLoading: isUsersDataLoading,
  } = useAdminGetAllUserQuery();
  const allUsers = allUsersData?.data?.data ?? [];
  console.log("allUsers", allUsers);

  const data = allUsers?.map((user) => ({
    key: user.id,
    name: user.name,
    email: user.email,
    status: user.role,
    phone: user.phone,
    address: user.address,
  }));

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className="mb-2 block"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="w-24"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            className="w-24"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      width: 130,
    },
    {
      title: "Role",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      width: 100,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      width: 100,
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      width: 100,
    },
    {
      title: "Actions",
      key: "actions",
      render: (userDataMapper) => (
        <div className="flex gap-x-2">
          <Button
            type="primary"
            danger
            ghost
            onClick={() => handleDeleteUserClick(userDataMapper.key)}
          >
            Delete
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  const handleDeleteUserClick = async (id) => {
    confirm({
      title: "Do you want to delete this user?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this user will be removed",
      async onOk() {
        try {
          // const res = await deleteUser(id);
          // res.data.message
          //   ? message.info(res.data.message)
          //   : message.success("User is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  return (
    <>
      <article className="users" style={{ direction: "ltr" }}>
        {/* {isUsersDataError && (
          <p className="text-red-600">Something went wrong</p>
        )} */}
        <section className="users_container">
          {/* <h2 className="text-2xl font-semibold">Users</h2> */}
          <h2 className="main_heading text-[1.5rem] font-bold">Users</h2>

          <div className="flex justify-center mb-5 gap-4">
            <Button type="primary" onClick={() => setOpenAddModal(true)}>
              <PlusOutlined /> Add Admin
            </Button>
          </div>
          <div className="container mx-auto my-5">
            <Table
              columns={columns}
              dataSource={data}
              loading={isUsersDataLoading}
              bordered
              // rowClassName={"rowName"}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [9, 10, 20, 30, 50],
                showTotal: (total) => `Total: ${total}`,
              }}
              scroll={{ y: 400, x: 500 }}
            />
          </div>
        </section>
      </article>
      <AddAdmin setOpenAddModal={setOpenAddModal} openAddModal={openAddModal} />
    </>
  );
};

export default UsersControl;
