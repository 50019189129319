import { Link } from "react-router-dom";
import img from "../../../assets/image/agri1.png";

const AgricultureImage = ({ category }) => {
  return (
    <Link
      to={`shop/catogery/${category.id}`}
      className={`border-[1px] solid border-[#ccc] min-w-[35%] 
		hilg:min-w-[35%] bg-[#cccccc3c] relative overflow-hidden`}
    >
      <div className="w-full h-full group ">
        <div className="absolute top-0 left-0 bg-[#cccccc5e] h-full w-full z-40"></div>
        <div className="absolute maxlm:top-[50%] top-[58%] maxlm:translate-y-[-50%] left-[30%] translate-x-[-30%] z-40">
          {/* <h2 className="text-[#0C8884] text-[40px] font-bold mb-4">
            {category.name}
          </h2>
          <p className="mb-4 text-[#606060] text-[16px]">
            We offer a comprehensive range of services for all the necessary
            components for successful poultry operations
          </p> */}
          {/* <span className="hover:text-[#0C8884] font-bold">Discover Now</span> */}
        </div>
        <img
          src={category.photo}
          className="w-full h-[400px]  group-hover:scale-[1.2] duration-[0.4s]"
          alt=""
        />
      </div>
    </Link>
  );
};

export default AgricultureImage;
