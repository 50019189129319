import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
  Form,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useEditBrandMutation,
  useGetFullBrandDetailsQuery,
} from "../../redux/Service/api"; // Changed API hooks

const EditBrand = ({
  setOpenEditModal,
  openEditModal,
  brandIdForEdit,
  rerenderEditKey,
}) => {
  const [form] = Form.useForm();
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState(null); // Track uploaded file

  // Fetch brand details
  const {
    data: allBrandData,
    isError: isAllBrandError,
    isLoading: isAllBrandLoading,
  } = useGetFullBrandDetailsQuery(brandIdForEdit); // Updated query

  const allBrandDetails = allBrandData?.data ?? {};

  useEffect(() => {
    form.setFieldsValue({
      nameAR: allBrandDetails?.name,
      nameEN: allBrandDetails?.name_en,
    });
    setFile(allBrandDetails?.photo); // Set the initial image
  }, [allBrandDetails]);

  const [editBrand, { isLoading: isEditLoading }] = useEditBrandMutation(); // Updated mutation

  const handleFileChange = ({ fileList }) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleEditBrand = async (values) => {
    const { nameAR, nameEN } = values;

    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("_method", "PUT"); // Required hidden method field
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);

    // Append the file only if it's a new upload (not the existing URL)
    if (typeof file !== "string") {
      formData.append("photo", file);
    }

    try {
      const res = await editBrand({ id: brandIdForEdit, formData });

      setFile(null);
      form.resetFields();
      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Brand updated successfully.");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error editing Brand:", error);
      message.error(
        "An error occurred while editing the brand. Please try again."
      );
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Brand"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer={null}
    >
      {isAllBrandLoading ? (
        <Spin size="small" style={{ marginRight: "0.2rem" }} />
      ) : isAllBrandError ? (
        <p className="text-red-500">Something went wrong</p>
      ) : (
        <Form form={form} layout="vertical" onFinish={handleEditBrand}>
          <Space direction="vertical" className="w-full" size="middle">
            <Form.Item
              name="nameAR"
              label="Name in Arabic"
              rules={[
                { required: true, message: "Please enter name in Arabic" },
              ]}
            >
              <Input placeholder="Name in Arabic" />
            </Form.Item>

            <Form.Item
              name="nameEN"
              label="Name in English"
              rules={[
                { required: true, message: "Please enter name in English" },
              ]}
            >
              <Input placeholder="Name in English" />
            </Form.Item>

            <Image
              src={typeof file === "string" ? file : ""}
              height={150}
              width={150}
              fallback="https://via.placeholder.com/150"
              alt="Brand"
            />

            <Form.Item label="Upload Image" required>
              <Upload
                customRequest={({ onSuccess }) => onSuccess?.(undefined)}
                onChange={handleFileChange}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Change Image (Max: 1)</Button>
              </Upload>
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              loading={isEditLoading}
              className="w-full"
            >
              Edit
            </Button>
          </Space>
        </Form>
      )}
    </Modal>
  );
};

export default EditBrand;
