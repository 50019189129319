import React, { useEffect, useState } from "react";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Tooltip,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useGetAllCategoriesQuery,
  useEditProductMutation,
  useGetFullProductDetailsQuery,
} from "../../redux/Service/api";
import dayjs from "dayjs";

const EditProduct = ({
  setOpenEditModal,
  openEditModal,
  productIdForEdit,
  rerenderEditKey,
}) => {
  const [form] = Form.useForm();
  // const [subImages, setSubImages] = useState([]);
  const [iconImage, setIconImage] = useState([]);

  const { data: allCategories } = useGetAllCategoriesQuery(undefined);
  const [editProduct, { isLoading }] = useEditProductMutation();

  const {
    data: allProductData,
    isError: isAllProductError,
    isLoading: isAllProductLoading,
    error: allProductError,
    refetch: refetchCategoryDetails,
  } = useGetFullProductDetailsQuery(productIdForEdit);

  const productDetails = allProductData?.data ?? null;

  useEffect(() => {
    if (productDetails && productIdForEdit) {
      form.setFieldsValue({
        name: productDetails.name,
        name_en: productDetails.name_en,
        price: productDetails.price,
        priceAfterDiscount: productDetails.discount,
        short_description: productDetails.short_description,
        short_description_en: productDetails.short_description_en,
        description: productDetails.description,
        descriptionEN: productDetails.description_en,
        available: productDetails.available ?? null,
        productionDate: dayjs(productDetails.production_date),
        categoryId: productDetails.category?.id,
      });

      setIconImage([
        {
          uid: "-1",
          name: "Main-image",
          status: "done",
          url: productDetails?.photo,
        },
      ]);
      // setSubImages(
      //   productDetails?.photos?.map((photo) => ({
      //     uid: photo.id,
      //     name: `image-${photo.id}`,
      //     status: "done",
      //     url: photo.path,
      //   }))
      // );
    }
  }, [productDetails, form]);

  // const handleSubImagesChange = ({ fileList }) => setSubImages(fileList);

  const handleSubmit = async (values) => {
    if (!iconImage.length) {
      message.error("Please upload a main image.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("name", values.name);
      formData.append("name_en", values.name_en);
      formData.append("price", values.price);
      formData.append("discount", values.priceAfterDiscount);
      formData.append("short_description", values.short_description);
      formData.append("short_description_en", values.short_description_en);
      formData.append("description", values.description);
      formData.append("description_en", values.descriptionEN);
      formData.append("available", "available");
      formData.append("production_date", values.productionDate);
      formData.append("category_id", values.categoryId);

      if (iconImage[0]?.originFileObj) {
        formData.append("photo", iconImage[0].originFileObj);
      }

      // subImages.forEach((file) => {
      //   if (file.originFileObj) formData.append("photos", file.originFileObj);
      // });

      await editProduct({ id: productDetails.id, formData });
      message.success("Product updated successfully!");
      setOpenEditModal(false);
    } catch (error) {
      message.error("Error updating product. Please try again.");
    }
  };

  return (
    <Modal
      title="Edit Product"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={800}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="space-y-4"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Form.Item name="name" label="Product Name (AR)" required>
            <Input placeholder="Enter Arabic product name" />
          </Form.Item>

          <Form.Item name="name_en" label="Product Name (EN)" required>
            <Input placeholder="Enter English product name" />
          </Form.Item>

          <Form.Item name="price" label="Price (Local)" required>
            <Input type="number" placeholder="Enter price" />
          </Form.Item>

          <Form.Item name="priceAfterDiscount" label="Price After Discount">
            <Input type="number" placeholder="Enter discounted price" />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Form.Item name="short_description" label="Short Description (AR)">
            <Input.TextArea
              rows={2}
              placeholder="Enter short description in Arabic"
            />
          </Form.Item>

          <Form.Item name="short_description_en" label="Short Description (EN)">
            <Input.TextArea
              rows={2}
              placeholder="Enter short description in English"
            />
          </Form.Item>

          <Form.Item name="description" label="Description (AR)" required>
            <Input.TextArea
              rows={4}
              placeholder="Enter product description in Arabic"
            />
          </Form.Item>

          <Form.Item name="descriptionEN" label="Description (EN)" required>
            <Input.TextArea
              rows={4}
              placeholder="Enter product description in English"
            />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Form.Item name="categoryId" label="Category" required>
            <Select placeholder="Select category">
              {allCategories?.data?.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item name="available" label="Availability" required>
            <Select placeholder="Select availability">
              <Select.Option value="active">Available</Select.Option>
              <Select.Option value="nonActive">Not Available</Select.Option>
            </Select>
          </Form.Item> */}

          <Form.Item name="productionDate" label="Production Date" required>
            <DatePicker format="YYYY-MM-DD" className="w-full" />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Upload
            listType="picture"
            fileList={iconImage}
            maxCount={1}
            onChange={({ fileList }) => setIconImage(fileList)}
            customRequest={({ onSuccess }) => onSuccess("ok")}
          >
            <Button icon={<UploadOutlined />}>Upload Main Image</Button>
          </Upload>

          {/* <Upload
            listType="picture"
            fileList={subImages}
            multiple
            maxCount={4}
            onChange={handleSubImagesChange}
            customRequest={({ onSuccess }) => onSuccess("ok")}
          >
            <Button icon={<UploadOutlined />}>Upload Sub Images</Button>
          </Upload> */}
        </div>

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          className="w-full"
        >
          Confirm
        </Button>
      </Form>
    </Modal>
  );
};

export default EditProduct;
