import { useParams } from "react-router";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import {
  useGetAllProductsByCategoryQuery,
  useGetCategoryDetailsQuery,
} from "../../redux/Service/api";
import ShopCart from "../Shop/ShopCart";
import MenuRight from "../../components/common/menuRight/MenuRight";
import { Link } from "react-router-dom";
import SkeletonCard from "../../components/SkeletonCard";

const ShopCatogery = () => {
  const { id } = useParams();
  const {
    data: allProductsData,
    isError: isAllProductsError,
    isLoading: isAllProductsLoading,
  } = useGetAllProductsByCategoryQuery(id);

  const allProducts = allProductsData?.data?.data ?? null;
  console.log("id", id);

  console.log("allCategories", allProducts);

  return (
    <>
      <h1>ShopCatogery ahmed</h1>
      <div className="flex flex-col">
        <ShopLayoutTitle
          title={"Home"}
          PathTo={"/"}
          Page={"Shop"}
          subPage={"Agri"}
        />
        <div className="min-h-[50vh] flex flex-wrap justify-start gap-x-[10px] gap-y-[25px] items-start w-full ">
          {isAllProductsLoading && <SkeletonCard />}
          {allProducts?.map((product) => {
            return (
              <div
                className="relative overflow-x-hidden w-[32.5%] duration-300 group"
                key={product.id}
              >
                <MenuRight product={product} />
                <Link
                  to={`/shop/catogery/products/${product.id}`}
                  className="pt-[12px] w-[100%]"
                >
                  <div className="h-[250px] w-full ">
                    <img src={product.photo} className=" h-full" alt="" />
                  </div>
                  <div className="flex flex-col items-center mt-4">
                    <p className="font-bold uppercase">{product.name}</p>
                    <p className="text-[#888] font-bold text-[13px]">
                      <span className="pr-1">{product.price}</span>
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShopCatogery;
