import CheckoutCart from "./CheckoutCart";

const CheckoutOrder = () => {
  return (
    <div className={`bg-[#fff] sticky top-0  w-full p-5 h-fit`}>
      <p className="text-[#222] font-bold border-b-[1px] border-[#ccc] pb-[10px]">
        Your order
      </p>
      <CheckoutCart />
      <CheckoutCart />
      <CheckoutCart />
      <CheckoutCart />
      <p className="border-b-[1px] border-[#ccc] pb-[10px] pt-[10px] flex justify-between flex-nowarp">
        <span className="text-[#cccccc]">Subtotal</span>
        <span className="text-[#888]">201.91 SAR</span>
      </p>
      <p className="border-b-[1px] border-[#ccc] pb-[10px] mb-4 pt-[10px] flex justify-between flex-nowarp">
        <span className="text-[20px] uppercase font-bold">Total</span>
        <span className="text-[18px]">201.91 SAR</span>
      </p>
    </div>
  );
};

export default CheckoutOrder;
