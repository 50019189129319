import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AgricultureLInks = ({ subCategoies }) => {
  return (
    <div className="pt-4 pb-4 w-[30%] maxlm:w-[100%]">
      {subCategoies.map((cat) => (
        <div className="mb-3 maxlm:w-[100%] maxlm:flex maxlm:flex-col ">
          <Link
            to={`shop/catogery/${cat.id}`}
            className="text-[#0B3954] font-bold block mb-4"
          >
            {cat.name}
          </Link>
          {cat.categories.length > 0 &&
            cat.categories.map((category) => (
              <Link
                to={`shop/catogery/Details/${category.id}`}
                className="text-[#828282] block mb-2"
              >
                <ArrowRightOutlined />
                <span className="pl-1">{category.name}</span>
              </Link>
            ))}
        </div>
      ))}
    </div>
  );
};

export default AgricultureLInks;
