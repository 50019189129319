import {
  LockOutlined,
  EyeOutlined,
  ShoppingOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import DrawerEye from "./DrawerEye";
import DrawerCart from "../DrawerCart";
import DrawerWishlist from "./DrawerWishlist";
import {
  useAddOrRemoveFavouriteProductMutation,
  useAddToCartMutation,
  useGetFavouritesProductsQuery,
} from "../../../redux/Service/api";
import { Spin, message } from "antd";
import { useSelector } from "react-redux";
import UserDrawer from "../../UserDrawer";
import { useState } from "react";

const MenuRight = ({ product = null }) => {
  const { isAuthenticated } = useSelector((st: any) => st.auth);
  const [customOpen, setCustomOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery(undefined);

  const favItems = favItemsData?.data ?? null;

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();

  const [
    addToCart,
    {
      data: addData,
      isLoading: addIsLoading,
      isError: addIsError,
      error: addError,
      isSuccess: addIsSuccess,
    },
  ] = useAddToCartMutation();

  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      message.info("You must be logged in");
      setCustomOpen(true);
    }
    try {
      const res = await addToCart({
        item_id: product?.id,
        quanity: 1,
      });
      message.info(res.data.message);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };
  const handleAddOrRemoveToFavourit = () => {
    if (product && product.id) {
      addOrDeleteProduct({ item_id: product.id });
    }
  };
  const handleShowProdDetails = () => {
    if (product && product.id) {
      navigate(`/shop/catogery/products/${product.id}`);
    }
  };

  return (
    <div className="absolute top-[50%] translate-y-[-65%] right-[-50px] z-50 flex flex-col items-center justify-center bg-black group-hover:right-2 transition-all duration-400 linear ">
      {/* <Link to={"/Cart"}>
        <ShoppingOutlined className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b]" />
      </Link> */}
      <UserDrawer customOpen={customOpen} />
      {addOrDelIsLoading && (
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-[#000000b5] flex items-center justify-center z-10">
          <Spin className="" />
        </div>
      )}

      <ShoppingOutlined
        className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
        onClick={handleAddToCart}
      />
      {/* <Link to={"/wishlist/1"}>
        <HeartOutlined className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b]" />
      </Link> */}
      {favItems?.find((el) => el.id === product?.id) ? (
        <HeartFilled
          className="text-red-500 text-[18px] cursor-pointer p-3 hover:bg-[#cccccc2b]"
          title="remove from favourite"
          onClick={handleAddOrRemoveToFavourit}
        />
      ) : (
        <HeartOutlined
          title="add to favourite"
          className="text-white text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
          onClick={handleAddOrRemoveToFavourit}
        />
      )}

      {/* <LockOutlined className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b]" /> */}
      {/* <EyeOutlined className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b]" /> */}
      {/* <DrawerEye /> */}
      <EyeOutlined
        className="text-[#ffff] text-[18px] p-3 hover:bg-[#cccccc2b] cursor-pointer"
        onClick={handleShowProdDetails}
      />
    </div>
  );
};

export default MenuRight;
