import { UserOutlined } from "@ant-design/icons";
import AcountForm from "./AcountForm";
import AcountNav from "./AcountNav";
import "./AcountDetails.scss";
const AcountDetails = () => {
  return (
    <>
      <div className="text-center pt-10 pb-10 bg-[#fafafa]">
        <UserOutlined />
        <span>My account</span>
      </div>
      <div className="pr-[50px] miillg:gap-[30px] miillg:flex-wrap milgg:pr-[20%] justify-between flex mt-11 milgg:pl-[20%] pl-[50px] miilg:pr-[25px] miilg:pl-[25px] bg-[#fff] mb-14">
        <AcountNav />

        <AcountForm />
      </div>
    </>
  );
};

export default AcountDetails;
