import React from "react";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import AboutLanding from "./AboutLanding";
import AboutTestimonials from "./AboutTestimonials";
import "./About.scss"
const Aboutus = () => {
  return (
    <>
      <div className="pr-[25px] milgg:pr-[20%] milgg:pl-[20%] pl-[25px] miilg:pr-[20px] miilg:pl-[20px] bg-[#fff] mb-14">
        <ShopLayoutTitle title={"Home"} PathTo={"/"} Page={"About Us"} />
        <AboutLanding />
      </div>

      <AboutTestimonials />
    </>
  );
};

export default Aboutus;
