import React from "react";
import AuthForm from "./AuthLoginForm";
import { UserOutlined } from "@ant-design/icons";
import AuthRegisterForm from "./AuthRegisterForm";
import './auth.scss'

const Auth = () => {
  return (
    <>
      <div className="flex items-center justify-center bg-[#fafafa] pt-8 pb-8 gap-2 text-[#0b3954]">
        <UserOutlined className="text-[12px]" />
        <h2 className="uppercase">My account</h2>
      </div>
      <div className="flex flex-nowrap xllg:flex-col pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] gap-[20px]">
        <div className="w-[50%] xllg:w-[100%] mt-5 mb-5">
          <AuthForm />
        </div>
        <div className="w-[50%] xllg:w-[100%] mt-5 mb-5">
          <AuthRegisterForm />
        </div>
      </div>
    </>
  );
};

export default Auth;
