// ShopAside
import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "./shopAside.scss";
import {
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const ShopAside: React.FC = () => {
  const [activeKey, setActiveKey] = useState(false); // State to track active panel

  const itemsNest: CollapseProps["items"] = [
    {
      key: "1",
      label: <span className="!pt-[12px]">Konoz agri</span>,
      children: (
        <>
          <p className="border-0 pl-[35px] pt-4">VDL Agrotech</p>
          <p className="border-0 pl-[35px] pt-4">VDL Agrotech</p>
          <p className="border-0 pl-[35px] pt-4">VDL Agrotech</p>
          <p className="border-0 pl-[35px] pt-4">VDL Agrotech</p>

        </>
      ),
    },
  ];
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center justify-between"
          onClick={() => setActiveKey((prev) => !prev)}
        >
          <span>Product categoriesAgri</span>
          {activeKey === true ? (
            <MinusOutlined className="ml-2" />
          ) : (
            <PlusOutlined className="ml-2" />
          )}
        </div>
      ),
      children: <Collapse accordion defaultActiveKey="1" items={itemsNest} />,
    },
  ];
  return (
    <>
      <div className="w-[200px] shopAside absolute top-[0px] z-40 miillg:hidden">
        <Collapse
          accordion
          expandIcon={() => null}
          items={items}
          className="bg-transparent border-0"
        />
      </div>
    </>
  );
};

export default ShopAside;
