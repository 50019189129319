// import FooterLogo from "../../assets/svg/FooterLogo.svg";
import FooterLogo from "../../assets/image/logo-e.png";
import {
  PhoneOutlined,
  AimOutlined,
  StarFilled,
  PaperClipOutlined,
} from "@ant-design/icons";
import FooterLeft from "../../assets/image/footerLeft.png";
import { Link } from "react-router-dom";
const FooterContent = () => {
  return (
    <>
      <div className="flex flex-wrap justify-between mt-2 border-t-2 solid hilg:flex-col">
        <div className="w-[100%] hilg:w-[100%] hilg:border-r-0 border-r-2 hilg:pr-0">
          <div className={`flex justify-between mt-3 miillg:flex-col`}>
            <div className={`w-[50%] miillg:w-[100%] miillg:flex miillg:items-center miillg:gap-10
              xllg:gap-0
              xllg:mb-10
              xllg:flex-col
              `}>
              <div className="w-[250px] miillg:w-full xllg:h-[200px] xllg:!w-[50%] xllg:mb-1 mt-4 mb-4 solid">
                <img src={FooterLogo} className="w-full xllg:h-full" alt="" />
              </div>
              <p className="text-[#8C8C8C] mb-6 miillg:mb-0 miillg:leading-[30px]">
                Providing clients with innovative ideas and practical solutions is
                one of our top priorities. These qualities have earned us a trusted
                reputation Our resources are dedicated to fostering innovation,
                maintaining high standards, and delivering exceptional value.
              </p>
            </div>
            <div className="flex flex-col items-center">

              <p className="mb-6">
                <PhoneOutlined className="text-[40px]" />
                <span className="text-[#000000] text-[30px] font-bold">
                  +966554608316
                </span>
              </p>
              <p className="mb-2">
                <AimOutlined className="text-[20px] pr-3" />
                <span className="text-[17px]">
                  Al-Hidaya Street and Abdullah Al-khayyat - Al-Aziziyah South,
                  Makkah
                </span>
              </p>
              <p className="mb-6">
                {/* <AimOutlined className="text-[20px] pr-3" /> */}
                <PaperClipOutlined className="text-[20px] pr-3" />
                <a
                  href="mailto:salem.mq@knoozelectric.com"
                  className="text-[17px] hover:underline hover:text-blue-500"
                >
                  salem.mq@knoozelectric.com
                </a>
              </p>
              {/* <div className="w-full">
                <div className="w-[120px]">
                  <img src={FooterLeft} className="w-full" alt="" />
                </div>
              </div> */}
            </div>
          </div>







          {/* <div className="mt-10 mb-10 text-center">
            <Link to={""} className="border-[1px] solid border-[#000000] p-4">
              <span className="pr-2">Review us on</span>
              <StarFilled className="pr-2 text-[18px] text-[#000000] fill-[#000000]" />
              <span className="font-bold capitalize text-[14px]">
                Trustpilot
              </span>
            </Link>
          </div> */}
        </div>
        {/* <div className="w-[35%] hilg:w-[50%]  flex justify-between mt-4 maxlm:flex-col maxlm:gap-[20px]"> */}
        {/* <div className="">
            <AgricultureMenu />
          </div>
          <div>
            <AgricultureMenu />
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default FooterContent;
