import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
const FooterTitle = () => {
	return (
		<div className="flex flex-wrap items-center justify-between pt-4 pb-4 border-b-2 vhilg:flex-col gap-y-[20px]">
		<div className="flex items-center gap-2 flex-nowrap ">
			<InboxOutlined className="text-[40px] miillg:text-[23px]" />
			<h2 className="text-[#0000000] text-[30px] miillg:text-[15px]">
				Subscribe Now & Get Best Offers
			</h2>
		</div>
		<div>
			<Space.Compact 
			className="w-[700px] miillg:w-[240px] h-[35px]"
			// style={{ width: "700px", height: "35px" }}
			
			>
				<Input
					placeholder="Your Email Adress..."
					className="w-full h-full border-none text-[15px] "
				/>
				<Button
					type="primary"
					className="h-full border-none bg-[#007dabd4] hover:!bg-[#000000] uppercase miillg:pr-2 miillg:pl-2 miillg:text-[11px]"
				>
					Subscribe
				</Button>
			</Space.Compact>
		</div>
	</div>
	)
}

export default FooterTitle