import { Skeleton } from "antd";

const SkeletonCard = () => (
  <div className="relative overflow-x-hidden duration-300 group min-w-[10rem]">
    <div className="h-[270px] w-full bg-[#cccccc79]">
      <Skeleton.Image className="w-[100%] h-[270px]" />
    </div>
    <div className="flex flex-col items-center mt-4">
      <Skeleton.Input active size="small" className="w-1/2 mb-1" />
      <Skeleton.Input active size="small" className="w-1/4" />
    </div>
  </div>
);
export default SkeletonCard;
