import {
  DatabaseFilled,
  HeartOutlined,
  HomeFilled,
  ShopFilled,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import BannerDrawer from "./BannerDrawer";
import "./Banner.scss";
import { useSelector } from "react-redux";
const Banner = () => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  return (
    <div
      className={`bg-[#007dab82] pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] Banner
      miilg:pl-[20px] miillg:fixed miillg:bottom-0 miillg:z-50 miillg:bg-[#ffffff] miillg:w-full miillg:shadow-[2px_0_12px_0_rgba(0,0,0,0.15)] `}
    >
      <div className="flex flex-row items-center gap-10 flex-nowrap text-[#000000]  miillg:justify-between">
        <NavLink
          to={"/"}
          className="miillg:flex miillg:flex-col miillg:items-center h-full block pt-2 pb-2"
        >
          <HomeFilled className="text-[20px] miillg:text-[30px] text-[#000000]" />
          <span className="miillg:text-[13px] niillg:hidden">Home</span>
        </NavLink>
        {/* <NavLink
          to={""}
          className="miillg:flex miillg:flex-col miillg:items-center niillg:hidden "
        >
          <ShopFilled className="text-[20px] miillg:text-[30px] text-[#000000]" />
          <span className="miillg:text-[13px] niillg:hidden">Home</span>
        </NavLink> */}
        <NavLink
          to={"/Acount"}
          className="miillg:flex miillg:flex-col miillg:items-center niillg:hidden"
        >
          <UserOutlined className="text-[20px] miillg:text-[30px] text-[#000000]" />
          <span className="miillg:text-[13px] niillg:hidden">Sign in</span>
        </NavLink>

        <BannerDrawer />

        <NavLink to={"/shop"} className="miillg:hidden text-[17px] h-full block pt-2 pb-2">
          Shop
        </NavLink>
        <NavLink
          to={"/aboutus"}
          className="miillg:hidden text-[17px] h-full block pt-2 pb-2"
        >
          About us
        </NavLink>
        {/* <NavLink to={""} className="miillg:hidden text-[17px] h-full block pt-2 pb-2">
          News
        </NavLink> */}
        <NavLink
          to={"/contactus"}
          className="miillg:hidden text-[17px] h-full block pt-2 pb-2"
        >
          Contact Us
        </NavLink>
        <NavLink
          to={"/wishlist/1"}
          className=" items-center gap-1 miillg:hidden text-[17px] h-full block pt-2 pb-2"
        >
          <HeartOutlined />
          <span className="pl-2">Wishlist</span>
        </NavLink>
        {/* <NavLink to={""} className="miillg:hidden text-[17px] h-full block pt-2 pb-2">
          <span>Compare</span>
        </NavLink> */}
        {isAuthenticated && (
          <NavLink
            to="/dashboard"
            className="miillg:hidden text-[17px] h-full block pt-2 pb-2"
          >
            <span>Dashboard</span>
          </NavLink>
        )}
      </div>

      <div></div>
    </div>
  );
};

export default Banner;
