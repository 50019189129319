import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "../pages/Home";
import Shop from "../pages/Shop";
import ShopLayout from "../components/ShopLayout";
import ShopCatogery from "../pages/ShopCatogery";
import ShopCatogeryDetails from "../pages/ShopCatogeryDetails/ShopCatogeryDetails";
import Wishlist from "../pages/Wishlist";
import CartPage from "../pages/CartPage";
import PaymentLayout from "../components/PaymentLayout";
import Checkout from "../pages/Checkout";
import Auth from "../pages/Auth";
import Aboutus from "../pages/Aboutus";
import Dashboard from "../Dashboard";
// import UsersControl from "../Dashboard/OrdersControl/OrdersControl";
import Categories from "../Dashboard/Categories/Categories";
import UsersControl from "../Dashboard/UsersControl/UsersControl";
import store from "../redux/store";
import ProductsControl from "../Dashboard/ProductsControl/ProductsControl";
import BannersControl from "../Dashboard/Banners/BannersControl";
// import PhotoSliders from "../Dashboard/SliderPhotos/PhotoSliders";
import ContactUs from "../pages/ContactUs";
import AcountDetails from "../pages/AcountDetails";
import Order from "../pages/Order";
import Adress from "../pages/Adress";
import BrandsControl from "../Dashboard/Brands/BrandsControl";
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "aboutus", element: <Aboutus /> },
      { path: "contactus", element: <ContactUs /> },
      { path: "acountDetails", element: <AcountDetails /> },
      { path: "order", element: <Order /> },
      // { path: "adress", element: <Adress /> },
      {
        path: "shop",
        element: <ShopLayout />,
        children: [
          { index: true, element: <Shop /> },
          { path: "/shop/catogery/:id", element: <Shop /> },
          { path: "/shop/catogery/details/:id", element: <ShopCatogery /> },
        ],
      },
      { path: "/shop/catogery/products/:id", element: <ShopCatogeryDetails /> },
      { path: "/wishlist/:id", element: <Wishlist /> },
      { path: "/Acount", element: <Auth /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    // loader: (e) => {
    //   if (store.getState().auth.userData?.role !== "admin") {
    //     window.location.href = "/";
    //   }
    //   return null;
    // },
    loader: (e) => {
      if (store.getState().auth.userData?.role !== "admin") {
        window.location.href = "/";
      }
      return null;
    },
    children: [
      { index: true, element: <UsersControl /> },
      { path: "users", element: <UsersControl /> },
      { path: "category", element: <Categories /> },
      { path: "brands", element: <BrandsControl /> },
      { path: "products", element: <ProductsControl /> },
      { path: "banners", element: <BannersControl /> },
    ],
  },
  {
    path: "/Cart",
    element: <PaymentLayout />,
    children: [
      { index: true, element: <CartPage /> },
      { path: "/Cart/checkout", element: <Checkout /> },
    ],
  },

  // {
  //   path: "/login",
  //   element: <Login />,
  //   errorElement: <ErrorPage />,
  // },
]);

export default router;
