import {
  CarryOutOutlined,
  EnvironmentOutlined,
  HeartOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import avaImage from "../../assets/image/avater.png";
import { useDispatch, useSelector } from "react-redux";
import { reduxLogout } from "../../redux/Slices/authSlice";
import {
  useGetLogedUserQuery,
  useLogoutMutation,
} from "../../redux/Service/api";
const AcountNav = () => {
  const { userData } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logout, { isLoading: logoutLoading, isError }] = useLogoutMutation();
  const handleLogoutClick = async () => {
    try {
      await logout(undefined);
      dispatch(reduxLogout());
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  return (
    <div className="border-r-[1px] miillg:border-r-0 miillg:w-[100%] w-[25%] flex flex-col gap-[30px] solid border-[#cccccc81] acountnav">
      <div className="flex flex-col gap-1 miillg:items-center">
        <div className=" rounded-[50%] w-[80px] h-[80px]">
          <img src={avaImage} alt="" className="w-full h-full rounded-[50%]" />
        </div>
        <h3 className="text-[16px]">{userData?.name}</h3>
        <p className="text-[#54595F] miillg:text-center">{userData?.email}</p>
      </div>
      <div className="flex flex-col gap-[30px]">
        <NavLink
          to={"/acountDetails"}
          className={` hover:text-[#8e8d8d] duration-300`}
        >
          <UserOutlined />
          <span className="pl-3 text-[16px]">Account details</span>
        </NavLink>
        <NavLink to={"/order"} className={` hover:text-[#8e8d8d] duration-300`}>
          <CarryOutOutlined />
          <span className="pl-3 text-[16px]">Orders</span>
        </NavLink>
        {/* <NavLink to={"/adress"} className={` hover:text-[#8e8d8d] duration-300`}>
					<EnvironmentOutlined />
					<span className="pl-3 text-[16px]">Adress</span>
				</NavLink> */}
        <NavLink
          to={"/wishlist/1"}
          className={` hover:text-[#8e8d8d] duration-300`}
        >
          <HeartOutlined />
          <span className="pl-3 text-[16px]">WishList</span>
        </NavLink>
        <NavLink
          to={"/"}
          className={` hover:text-[#8e8d8d] duration-300`}
          onClick={handleLogoutClick}
        >
          <LogoutOutlined />
          <span className="pl-3 text-[16px]">Log Out</span>
        </NavLink>
      </div>
    </div>
  );
};

export default AcountNav;
