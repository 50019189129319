
type IProps = {
	title: string, discreption: string, icon: React.ReactNode, CustomStyle? : string
}
const ContactUsBox = ({ title, discreption, icon , CustomStyle}: IProps) => {
	return (
		<div className={`flex gap-[20px] flex-nowrap maxlm:flex-col maxlm:items-center rounded-[15px] items-center bg-[#f8f8f8]
		 hiilg:w-[48%] miillg:w-[100%] w-[32%] ${CustomStyle} pr-10 pl-10 pt-5 pb-5`}>
			<div 
			className="bg-[#fff] maxlm:text-[19x] maxlm:min-w-[50px] maxlm:min-h-[50px] min-w-[65px] min-h-[65px] border-[1px] border-[#00235A] solid rounded-[50%] text-[25px] flex justify-center items-center">
				{icon}
			</div>
			<div className="maxlm:text-center">
				<p className="text-[23px] maxlm:text-[16px] text-[#00235A] font-bold">
					{title}
				</p>
				<p className={`text-[18px] maxlm:text-[14px]`}>
					{discreption}
				</p>
			</div>
		</div>
	)
}

export default ContactUsBox