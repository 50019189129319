import { Button, Layout, Menu } from "antd";
import React, { useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
import { reduxLogout } from "../redux/Slices/authSlice";
import {
  ShopOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  AppstoreOutlined,
  TagsOutlined,
  ShoppingOutlined,
  PictureOutlined,
} from "@ant-design/icons";

const { Content, Footer, Sider } = Layout;

const sidebarTabs = [
  {
    key: "users",
    label: "Users",
    icon: <UserOutlined />, // User Management
  },
  {
    key: "category",
    label: "Categories",
    icon: <AppstoreOutlined />, // Categories or Collections
  },
  {
    key: "brands",
    label: "Brands",
    icon: <TagsOutlined />, // Brand Tags or Labels
  },
  {
    key: "products",
    label: "Products",
    icon: <ShoppingOutlined />, // Products or Store Items
  },
  {
    key: "banners",
    label: "Banners",
    icon: <PictureOutlined />, // Banners or Images
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((st) => st.auth);
  const [siderCollapsed, setSiderCollapsed] = React.useState(false);

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleTabClick = (event) => {
    const clickedTabKey = event.key;
    navigate(`/dashboard/${clickedTabKey}`);
  };
  const handleLogout = () => {
    // localStorage.removeItem("unitedUserToken");
    dispatch(reduxLogout());
  };
  return (
    <Layout className="dashboard" hasSider>
      <Sider
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed) => setSiderCollapsed(collapsed)}
        trigger={
          siderCollapsed ? (
            <MenuUnfoldOutlined className="trigger-icon" />
          ) : (
            <MenuFoldOutlined className="trigger-icon" />
          )
        }
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <div
          className="logo mb-2 text-center p-2"
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          {/* <img src={logo} alt="logo" style={{ width: "100%" }} /> */}
          Knooz
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["users"]}
          items={sidebarTabs}
          onClick={handleTabClick}
        />
        <div
          style={{
            position: "absolute",
            bottom: "3.5rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // overflow: "hidden",
          }}
        >
          <Button
            type="primary"
            style={{ width: "80%" }}
            onClick={handleLogout}
          >
            logout
          </Button>
        </div>
      </Sider>
      <Layout
        style={
          {
            // marginLeft: 200,
          }
        }
      >
        <Content
          // style={{ margin: !siderCollapsed ? "0px 0 0 12rem" : "initial" }}
          style={{ margin: !siderCollapsed ? "0px 0 0 12rem" : "0 0 0 5rem" }}
        >
          <div className="site-layout-background content">
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          prosoft
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
