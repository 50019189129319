import PopulatedText from "./PopulatedText";
import PopulatedCatogery from "./PopulatedCatogery";
import { useGetAllSpecialProductsQuery } from "../../../redux/Service/api";

const PopulatedSection = () => {
  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
  } = useGetAllSpecialProductsQuery(undefined);
  const allSpecialProducts = allProductsData?.data?.data ?? [];
  if (allSpecialProducts?.length == 0) {
    return;
  }
  return (
    <div className="mb-[9px]">
      <PopulatedText />
      <PopulatedCatogery allSpecialProducts={allSpecialProducts} />
    </div>
  );
};

export default PopulatedSection;
