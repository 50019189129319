import {
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import {
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../redux/Service/api";
import ServerError from "../../components/utils/ServerError";

const { confirm } = Modal;

function Categories() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);

  const allCategoriesSameLevel = flattenCategories(
    allCategoriessData?.data ?? []
  );

  console.log("allCategoriesSameLevel", allCategoriesSameLevel);

  function flattenCategories(categories) {
    return categories.reduce((acc, category) => {
      // Add the current category to the accumulator
      acc.push({
        ...category,
      });

      // If the category has children, recursively flatten them
      if (category.categories && category.categories.length > 0) {
        acc.push(...flattenCategories(category.categories));
      }

      return acc;
    }, []);
  }

  const [deleteCategory, { isLoading: delIsLoading }] =
    useDeleteCategoryMutation();

  const handleDeleteCategory = async (id) => {
    confirm({
      title: "Do you really want to delete this category?",
      icon: <ExclamationCircleFilled />,
      content: "Upon clicking OK, this category will be removed.",
      async onOk() {
        try {
          const res = await deleteCategory(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Category deleted successfully.");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (photo) =>
        photo ? (
          <img
            src={photo}
            alt="Category"
            style={{ width: 50, height: 50, objectFit: "contain" }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, category) => (
        <div className="flex gap-2">
          <Tooltip title="Edit">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                setOpenEditModal(true);
                setCategoryIdForEdit(category.id);
                setRerenderEditKey((prev) => prev + 1);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="text"
              icon={<DeleteFilled />}
              danger
              onClick={() => handleDeleteCategory(category.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section className="categories section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">Categories</h2>
      {isAllCategoriesError && <ServerError />}

      <div className="flex justify-center mb-5">
        <Button
          type="primary"
          onClick={() => setOpenAddModal(true)}
          icon={<PlusOutlined />}
        >
          Add New Category
        </Button>
      </div>

      <div className="container mx-auto my-5">
        <Table
          columns={columns}
          dataSource={allCategoriesSameLevel}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          loading={isAllCategoriesLoading || delIsLoading}
        />
      </div>

      <EditCategory
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        categoryIdForEdit={categoryIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />
      <AddCategory
        allCategories={allCategoriesSameLevel}
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
    </section>
  );
}

export default Categories;
