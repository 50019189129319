// import React from 'react'

// const UserTab = () => {
// 	return (
// 		<div>UserTab</div>
// 	)
// }

// export default UserTab
import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import UserLogin from './UserLogin';
import Register from './Register';

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'LOGIN',
    children: (
			<>
			<UserLogin/>
			</>
		),
  },
  {
    key: '2',
    label: 'REGISTER',
    children: (
			<>
			<Register/>
			</>
		),
  },
];

const UserTab: React.FC = () => <div className='w-full userTab'>
	<Tabs defaultActiveKey="1" items={items} onChange={onChange} />
</div>;

export default UserTab;