import ShopCart from "./ShopCart";
import ShopBanner from "../../assets/image/ShopBanner.png";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import {
  useGetAllCategoriesQuery,
  useGetCategoryDetailsQuery,
} from "../../redux/Service/api";
import { useNavigate, useParams } from "react-router";
import MenuRight from "../../components/common/menuRight/MenuRight";
import { Link } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import { Skeleton, Spin } from "antd";
import SkeletonCard from "../../components/SkeletonCard";
const Shop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: allCategoriesData,
    isLoading: isAllCategoriesLoading,
    isError: isAllCategoriesError,
  } = useGetAllCategoriesQuery(undefined);

  const {
    data: categoryDetailsData,
    isLoading: isCategoryDetailsLoading,
    isError: isCategoryDetailsError,
  } = useGetCategoryDetailsQuery(id, { skip: !id }); // Skip if no id

  // Use category details if id exists, otherwise all categories
  const categories = id
    ? categoryDetailsData?.data?.categories || []
    : allCategoriesData?.data || [];

  const handleCategoryClick = (category) => {
    if (category.categories.length > 0 && !category.items_count) {
      navigate(`/shop/catogery/${category.id}`);
    } else if (category.items_count) {
      navigate(`/shop/catogery/Details/${category.id}`);
    }
  };

  return (
    <div className="flex flex-col relative">
      <ShopLayoutTitle title={"Home"} PathTo={"/"} Page={"Shop"} />

      <div className="min-h-[50vh] flex flex-wrap justify-start maxlm:justify-center gap-x-[25px] gap-y-[25px] items-start w-full relative">
        {(isCategoryDetailsLoading || isAllCategoriesLoading) && (
          <SkeletonCard />
        )}
        {categories.map((category) => (
          <div className="relative overflow-x-hidden duration-300 group">
            <span
              onClick={() => handleCategoryClick(category)}
              className="pt-[12px] min-w-[21.2%]"
            >
              <div className="h-[270px] w-full bg-[#cccccc79]">
                <img src={category.photo} className="w-full h-full" alt="" />
              </div>
              <div className="flex flex-col items-center mt-4">
                <p className="font-bold uppercase">{category.name}</p>
                <p className="text-[#888] font-bold text-[13px]">
                  <span className="pr-1">
                    {category.items_count
                      ? category.items_count
                      : category.categories.length}
                  </span>
                  <span>products</span>
                </p>
              </div>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;
