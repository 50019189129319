import { ShoppingOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import DrawerCart from "../../common/DrawerCart";
import UserDrawer from "../../UserDrawer";
import { Link } from "react-router-dom";

type IProps = {
  isAuthenticated: boolean;
};
const EcommarceIcon = ({ isAuthenticated }: IProps) => {
  let isauth = true;
  return (
    <div className="pl-[40px] flex items-center gap-[20px]">
      {isAuthenticated === true ? (
        <div className="miillg:flex miillg:flex-col miillg:items-center">
          <Link to={"acountDetails"}>
            <UserOutlined className="text-[15px] border-[2px] border-[black] rounded-[50%] p-1 miillg:hidden" />
          </Link>
        </div>
      ) : (
        <UserDrawer />
      )}

      {/* <UserOutlined className="text-[15px] border-[2px] border-[black] rounded-[50%] p-1 miillg:hidden" /> */}
      <div>
        {/* <DrawerCart CustomStyle={"text-[27px] miillg:text-[35px]"} /> */}
        <Link to={"/cart"}>
          <ShoppingOutlined className="text-[27px] miillg:text-[35px]" />
        </Link>
      </div>
    </div>
  );
};

export default EcommarceIcon;
