import React from 'react';
import type { FormInstance } from 'antd';
import { Button, Form, Input, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';

interface SubmitButtonProps {
    form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
    const [submittable, setSubmittable] = React.useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(true));
    }, [form, values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable}>
            {children}
        </Button>
    );
};

const ContactUsForm: React.FC = () => {
    const [form] = Form.useForm();
    return (
        <div className='contactForm'>
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                <div className='flex xllg:flex-col w-full justify-between customitem'>
                    <Form.Item name="name" label="Name *" rules={[{ required: false }]}>
                        <Input className='h-[50px]' placeholder='Enter Name'/>
                    </Form.Item>
                    <Form.Item name="email" label="Email *" rules={[{ required: false }]}>
                        <Input className='h-[50px]'placeholder='Enter e-mail' />
                    </Form.Item>
                </div>
                <Form.Item name="subject" label="Subject *" rules={[{ required: false }]}>
                    <Input className='h-[50px]'/>
                </Form.Item>
                <Form.Item name="message" label="Message *" rules={[{ required: false }]}>
                <TextArea className=' !resize-none' rows={4} placeholder="Write message" maxLength={6} />

                </Form.Item>
                <Form.Item>
                    <Space>
                        <SubmitButton  form={form}>Submit</SubmitButton>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ContactUsForm;
