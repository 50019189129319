import React, { useState } from "react";
import type { DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space } from "antd";
import {
  CloseOutlined,
  DatabaseFilled,
  HeartOutlined,
  HomeFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import UserTab from "./UserTab";

const UserDrawer = ({ customOpen = false }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Space className="cursor-pointer">
        <div
          onClick={showDrawer}
          className="miillg:flex miillg:flex-col miillg:items-center"
        >
          <UserOutlined className="text-[15px] border-[2px] border-[black] rounded-[50%] p-1 miillg:hidden" />{" "}
        </div>
      </Space>
      <Drawer
        title={
          <div>
            <CloseOutlined
              onClick={() => setOpen(false)}
              className="text-[25px]"
            />
          </div>
        }
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={open || customOpen}
        key={placement}
        className="Draower_more"
      >
        <div className="flex flex-col items-center gap-4 text-[#0b3954]">
          <UserTab />
        </div>
      </Drawer>
    </>
  );
};

export default UserDrawer;
