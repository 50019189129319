import img from "../../assets/image/ShopCard.png";

const CheckoutCart = () => {
  return (
    <div className="flex gap-4 pt-5 pb-5 border-b-[1px] border-[#ccc] flex-nowarp">
      <div className="w-[70px] h-[70px] ">
        <img src={img} className="w-full h-full" alt="" />
      </div>
      <div>
        <p> AF10-B54</p>
        <p className="text-[#8c8c8c]">1 × 201.91 SAR</p>
        <p>
          <span>Subtotal:</span>
          <span className="#8c8c8c pl-2">201.91 SAR</span>
        </p>
      </div>
    </div>
  );
};

export default CheckoutCart;
