import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/free-mode"; // Styles for free-mode
import "swiper/css/autoplay";

import img from "../../assets/image/aboutSlider.png";
import { useMediaQuery } from "react-responsive";
const AboutSlider = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const isMobile600 = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div className="w-full bg-[#fff] ">
      <Swiper
        className="h-[180px]"
        spaceBetween={20}
        slidesPerView={isMobile ? isMobile600 ? 1: 2 : 4}
        loop={true}
        autoplay={{
          delay: 0, // No delay between transitions
          disableOnInteraction: false,
        }}
        freeMode={true}
        speed={2000} // Adjust this value to control the sliding speed
        modules={[Autoplay, FreeMode]} // Pass the modules here
      >
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="w-full h-full" src={img} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AboutSlider;
