import FooterButtom from "./FooterButtom";
import FooterContent from "./FooterContent";
import FooterTitle from "./FooterTitle";

const Footer = () => {
  return (
    <div className="">
      <FooterTitle />
			<FooterContent/>
			<FooterButtom/>
    </div>
  );
};

export default Footer;
