import { LoginOutlined, UserAddOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const ContactAcountDetails = () => {
    return (
            <div className='p-8 bg-[#EDF4F4] flex flex-col xllg:w-[100%]'>
                <div className='mb-11 maxlm:mb-5'>
                    <h2 className='text-[#0B3954] 
                    maxlm:text-[16px] text-[23px] font-bold mb-5 maxlm:mb-3'>
                        Account details
                    </h2>
                    <Link to={``} className=' underline leading-[30px] maxlm:text-[12px]'>
                        Account details
                        Track your last order, View all orders or manage your addresses.
                    </Link>
                </div>
                <div className='flex flex-col gap-5 maxlm:gap-3'>
                    <Link to={``} className='maxlm:text-[14px]'>
                    <LoginOutlined className='pr-2'/>
                    <span className=' underline'>Sign in</span>
                    </Link>
                    <Link to={``} >
                    <UserAddOutlined className='pr-2'/>
                    <span className=' underline'>Create an account</span>
                    </Link>
                </div>
            </div>
    )
}

export default ContactAcountDetails
