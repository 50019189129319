import { Link, useNavigate } from "react-router-dom";
import CartDelevery from "./CartDelevery";
import CartTable from "./CartTable";
import "./Cart.scss";
import CartDetails from "./CartDetails";
import CartEmpty from "./CartEmpty";
import { useSelector } from "react-redux";
import { useGetCartProductsQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
const CartPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);

  const userAddress = userData?.address ? userData.address : "";

  const {
    data: cartData,
    isError: isCartError,
    isLoading: isCartLoading,
    error: cartError,
  } = useGetCartProductsQuery(undefined);

  const allCartData = cartData?.data ?? [];

  console.log("cartData", allCartData);

  const emptyCrt = allCartData?.length === 0 ? true : false;
  if (emptyCrt && !isCartLoading) {
    return <CartEmpty />;
  }
  return (
    <div className=" cart relative flex justify-between w-full hiilg:flex-col hiilg:gap-[20px] ">
      {isCartLoading && <Loading />}
      <div className="flex flex-col w-[60%] hiilg:w-[100%]">
        <CartDelevery />
        <CartTable allCartData={allCartData.data} />
      </div>
      <CartDetails allCartData={allCartData} />
    </div>
  );
};

export default CartPage;
