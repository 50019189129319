import React from 'react'
import CartLogo from '../../assets/image/logo-e.png'
import PaymentHeaderLinks from './PaymentHeaderLinks/PaymentHeaderLinks'
import { Link } from 'react-router-dom'
const PaymentHeader = () => {
	return (
		<div className={`milgg:pr-[20%] miillg:flex-col miillg:gap-[20px] pr-[50px] maxlm:pr-[25px] maxlm:pl-[25px] pl-[50px] 
		milgg:pl-[20%] bg-[#0b3954] pt-[10px] pb-[10px] flex justify-between items-center`}>
			<Link to={"/"} className='w-[100px] h-[80px]'>
				<img src={CartLogo} className='w-full' alt="" />
			</Link>
			<PaymentHeaderLinks/>
		</div>
	)
}

export default PaymentHeader