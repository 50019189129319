import React from "react";

type IProps = {
  title: string;
  icon: React.ReactNode;
  describtion: string;
};
const ServicesSectionBox = ({ title, icon, describtion }: IProps) => {
  return (
    <>
      <div className={`flex gap-4 min-w-[25.5%] maxlm:min-w-[100%]`}>
				{icon}
      <div >
        <h2
          className={`text-[#0B3954] text-[19px] xxllg:text-[28px] maxlm:text-[20px] font-bold `}
        >
          {title}
        </h2>
        <p className={`text[#5C676D] text-[16px] maxlm:text-[12px] xxllg:text-[14px]`}>
          {describtion}
        </p>
      </div>
      </div>
    </>
  );
};

export default ServicesSectionBox;
